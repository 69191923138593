// ----------------------------------------------------------------------

import Iconify from "components/iconify";

const navConfig = {
  admin: [
    {
      title: "דף בית",
      path: "/",
      icon: <Iconify icon="mdi:home-analytics" />,
    },
    {
      title: "משתמשים",
      path: "/users",
      icon: <Iconify icon="mdi:user-group" />,
    },
    {
      title: "מיקומים",
      path: "/locations",
      icon: <Iconify icon="mdi:truck-minus-outline" />,
    },
  ],
  driver: [
    {
      title: "דף בית",
      path: "/",
      icon: <Iconify icon="mdi:home-analytics" />,
    },
  ],
  manager: [
    {
      title: "דף בית",
      path: "/",
      icon: <Iconify icon="mdi:home-analytics" />,
    },
  ],
};

export default navConfig;
