import { Suspense } from "react";
import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Router from "./routes/sections";
import ThemeProvider from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Toaster } from "react-hot-toast";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="he">
      <CacheProvider value={cacheRtl}>
        <ThemeProvider>
          <Toaster position="top-center" reverseOrder={false} />
          <Suspense>
            <Router />
          </Suspense>
        </ThemeProvider>
      </CacheProvider>
    </LocalizationProvider>
  );
}
