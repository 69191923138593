export const roles: Record<string, string> = {
  admin: "אדמין",
  driver: "נהג",
  manager: "מנהל",
};

export const locationType: Record<string, string> = {
  TRUCK: "משאית",
  WAREHOUSE: "מחסן",
  MACHINE: "מכונה",
};
