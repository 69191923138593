import { lazy, useMemo } from "react";
import { Outlet, Navigate, useRoutes, useLocation } from "react-router-dom";

import DashboardLayout from "layouts";
import { useAuth } from "auth";

export const LoginPage = lazy(() => import("pages/login"));
export const Page404 = lazy(() => import("pages/page-not-found"));
export const IndexPage = lazy(() => import("pages/index"));
export const UsersPage = lazy(() => import("pages/users"));
export const LocationsPage = lazy(() => import("pages/locations"));

// ----------------------------------------------------------------------
const UnauthorizedErrorHandler = () => {
  const { pathname } = useLocation();
  return (
    <Navigate
      to={"/auth/login"}
      state={{
        redirectTo: pathname,
      }}
    />
  );
};

const LoginRedirectionHandler = () => {
  const { state } = useLocation();

  if (state && state.redirectTo) {
    window.history.pushState(null, "", "/");
    return <Navigate to={state.redirectTo} />;
  }

  return <Navigate to={"/"} />;
};

export default function Router() {
  const { currentUser } = useAuth();

  const roleRoutes = useMemo(() => {
    switch (currentUser?.role) {
      case "admin":
        return [
          { path: "users", element: <UsersPage /> },
          { path: "locations", element: <LocationsPage /> },
        ];
      case "driver":
        return [];
      case "manager":
        return [];
      default:
        return [];
    }
  }, [currentUser]);

  const routes = useRoutes([
    {
      element: currentUser ? (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ) : (
        <UnauthorizedErrorHandler />
      ),
      children: [
        ...roleRoutes,
        { element: <IndexPage />, index: true },
        { path: "/:id", element: <IndexPage /> },
      ],
    },
    {
      path: "auth",
      element: !currentUser ? <Outlet /> : <LoginRedirectionHandler />,
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        { path: "login", element: <LoginPage /> },
      ],
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: currentUser ? (
        <Navigate to="/404" replace />
      ) : (
        <UnauthorizedErrorHandler />
      ),
    },
  ]);

  return routes;
}
